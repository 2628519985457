import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useFormNovoSistemaValidation } from './form-novo-sistema-validation';
import { makeUtilClasses, useThemeQueries } from 'views';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { ImagemInput, TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NovoSistemaCadastroFormModel } from 'model/app/forms/sistemas/novo-sistema-cadastro-form-model';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { SimNaoMock } from 'data/mocks';

export const FormNovoSistemaCadastro = forwardRef<
  DefaultFormRefs<NovoSistemaCadastroFormModel>,
  DefaultFormProps<NovoSistemaCadastroFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<NovoSistemaCadastroFormModel>,
    ref,
  ) => {
    const { FormNovoSistemaValidationYup } = useFormNovoSistemaValidation();

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue
    } = useForm<NovoSistemaCadastroFormModel>({
      resolver: yupResolver(FormNovoSistemaValidationYup),
      criteriaMode: 'all',
      mode: 'onChange',
    });
    const refInputSenhaAtual = useRef<HTMLInputElement>(null);
    const utilClasses = makeUtilClasses();
    const { isMobile } = useThemeQueries();

    const submitNovoSistema = useCallback(
      (data: NovoSistemaCadastroFormModel) => {
        onSubmit(data);
      },
      [onSubmit],
    );

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitNovoSistema)();
      },
      resetForm: () => {
        if (!isMobile) {
          refInputSenhaAtual.current?.focus();
        }

        reset(new NovoSistemaCadastroFormModel());
      },
      fillForm: () => {
        if (!isMobile) {
          refInputSenhaAtual.current?.focus();
        }
        reset();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitNovoSistema)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <Controller
                    name="urlImagem"
                    control={control}
                    render={({ field }) => (
                      <ImagemInput
                        loadErrorImage={SemImagem}
                        accept='image/*'
                        imageRadius="50%"
                        width={isMobile ? '180px' : '200px'}
                        height={isMobile ? '180px' : '200px'}
                        error={Boolean(
                          errors.urlImagem && errors.urlImagem.message,
                        )}
                        helperText={
                          touchedFields.urlImagem || errors.urlImagem
                            ? errors.urlImagem?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('urlImagem')}
                        onChange={({ base64 }: any) => {
                          setValue('urlImagem', base64);
                        }}
                        onBlur={() => {
                          setValue('urlImagem', '');
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputSenhaAtual}
                        id="nome"
                        label="Nome"
                        tipo="TEXTO"
                        fullWidth={true}
                        error={Boolean(
                          errors.nome && errors.nome.message,
                        )}
                        helperText={
                          touchedFields.nome || errors.nome
                            ? errors.nome?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="descricao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        tipo="TEXTO"
                        multiline
                        rows={4}
                        id="descricao"
                        label="Descrição"
                        fullWidth={true}
                        error={Boolean(
                          errors.descricao && errors.descricao.message,
                        )}
                        helperText={
                          touchedFields.descricao || errors.descricao
                            ? errors.descricao?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="permiteMultiContratos"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={loading}
                        conteudo={SimNaoMock.map(x => ({
                          ...x,
                          Key: Boolean(x.Key),
                        }))}
                        id="permiteMultiContratos"
                        label="Permite Multi-Contratos"
                        fullWidth={true}
                        error={Boolean(
                          errors.permiteMultiContratos && errors.permiteMultiContratos.message,
                        )}
                        helperText={
                          touchedFields.permiteMultiContratos || errors.permiteMultiContratos
                            ? errors.permiteMultiContratos?.message
                            : undefined
                        }
                        {...field}
                        onChange={(e: any) => {
                          const value = e.target.value;
                          const selecao = SimNaoMock.find(
                            (item) => Boolean(item.Key) === value,
                          );
                          if (!selecao) return;
                          const newValue = Boolean(selecao.Key);
                          setValue('permiteMultiContratos', newValue);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
