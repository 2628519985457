import { EnumTipoComunicacao } from "model";
import { KeyValueModel } from "model/api";

export const TipoComunicacaoMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoComunicacao.Boleto, "Boleto"),
    new KeyValueModel(EnumTipoComunicacao.Pix, "PIX"),
    new KeyValueModel(EnumTipoComunicacao.Dinheiro, "Dinheiro"),
    new KeyValueModel(EnumTipoComunicacao.Transferencia, "Transferência"),
    new KeyValueModel(EnumTipoComunicacao.PixSplit, "Pix S2Pay"),
    new KeyValueModel(EnumTipoComunicacao.Nenhuma, 'Nenhuma')
];