import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        gridGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%'
    },
    dataCredenciamento: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& p': {
            color: theme.palette.grey[500],
            fontSize: '13px'
        }
    }
}));
