import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useGetPessoaTaxa } from "data/api/gestao/pessoa-taxa/get-pessoa-taxa";
import { usePostPessoaTaxa } from "data/api/gestao/pessoa-taxa/post-pessoa-taxa";
import { usePutPessoaTaxa } from "data/api/gestao/pessoa-taxa/put-pessoa-taxa";
import { TipoTaxaMock } from "data/mocks/tipo-taxa-mock";
import { isEmpty } from "lodash";
import { PessoaTaxaModel } from "model/api/gestao/pessoa-taxa/pessoa-taxa-model";
import { PessoaTaxaPostModel } from "model/api/gestao/pessoa-taxa/pessoa-taxa-post-model";
import { PessoaTaxaFormModel, TaxaFormModel } from "model/app/forms/taxa/pessoa-taxa-form-model";
import { EnumInstituicao } from "model/enums/enum-instituicao";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { EnumTipoTaxa } from "model/enums/enum-tipo-taxa";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { guidEmpty } from "utils/guid-empty";
import { picker } from "utils/picker";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { FormTaxas } from "views/components/form/master/taxas/form-taxas";
import { DefaultFormRefs } from "views/components/form/utils";
import { SalvarIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import { useStyles } from './accordion-cliente-taxa-styles'
import { toDateString } from "utils/to-date";

const convertTaxa = (model: PessoaTaxaModel) => {
  return picker<TaxaFormModel>(model, new TaxaFormModel())
}

const novoModeloTaxa = (tipo: EnumTipoTaxa, credencialId: string = '', pessoaId: string = '', tpPessoa: EnumTipoPessoas) => {
  return new TaxaFormModel(
    guidEmpty(),
    credencialId,
    pessoaId,
    tpPessoa,
    2,
    EnumInstituicao.Safra,
    tipo,
  )
}

const sortTaxas = (a: PessoaTaxaModel, b: PessoaTaxaModel) => {
  if (a.tipoPessoa === EnumTipoPessoas.SoftwareHouse && b.tipoPessoa !== EnumTipoPessoas.SoftwareHouse) {
    return -1;
  } else if (a.tipoPessoa !== EnumTipoPessoas.SoftwareHouse && b.tipoPessoa === EnumTipoPessoas.SoftwareHouse) {
    return 1;
  } else {
    if (a.tipoTaxa === EnumTipoTaxa.CUSTO && b.tipoTaxa === EnumTipoTaxa.VENDA) {
      return -1;
    } else if (a.tipoTaxa === EnumTipoTaxa.VENDA && b.tipoTaxa === EnumTipoTaxa.CUSTO) {
      return 1;
    } else {
      return 0;
    }
  }
}

interface ClienteTaxaLocationProps {
  from: {
    open: string;
  }
}

type Props = {
  id: string;
  tipoPessoa: EnumTipoPessoas;
}

export const AccordionClienteTaxa = ({ id, tipoPessoa }: Props) => {
  const { theme } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(false)
  const [showBtn, setShowBtn] = useState<boolean>(false);
  // const [showAtivar, setShowAtivar] = useState<boolean>(false);
  const { showToast } = useToastSaurus()
  const { state } = useLocation<ClienteTaxaLocationProps>()
  const classes = useStyles();
  const { tipoUsuario } = useSessaoAtual();

  const { getPessoaTaxa, carregando: carregandoGet } = useGetPessoaTaxa();
  const { putPessoaTaxa, carregando: carregandoPut } = usePutPessoaTaxa();
  const { postPessoaTaxa, carregando: carregandoPost } = usePostPessoaTaxa();
  // const { postCredenciaisAtivar, carregando: carregandoPostCred } = usePostCredenciaisAtivar();

  const carregando = carregandoGet || carregandoPut || carregandoPost;

  const formRef = useRef<DefaultFormRefs<PessoaTaxaFormModel>>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const dataRef = useRef<string>('')

  const isSoftwareHouse = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse

  const search = useCallback(
    async () => {
      try {
        const res = await getPessoaTaxa(id);

        if (res.erro) throw res.erro

        let taxas = res.resultado?.data;

        if (!taxas || isEmpty(taxas)) {
          if (tipoPessoa === EnumTipoPessoas.SoftwareHouse) {
            const taxaVendaSW = novoModeloTaxa(EnumTipoTaxa.VENDA, '', id, tipoPessoa)
            formRef.current?.fillForm(new PessoaTaxaFormModel([taxaVendaSW]))
            setShowBtn(true)
            return
          }
          formRef.current?.fillForm(new PessoaTaxaFormModel([]))
          return
        }
        switch (tipoPessoa) {
          case EnumTipoPessoas.SoftwareHouse:
            const taxaSW = taxas[0];
            const convertModel = convertTaxa(taxaSW);
            formRef.current?.fillForm(new PessoaTaxaFormModel([convertModel]))
            setShowBtn(true)
            break;
          case EnumTipoPessoas.Cliente:
            if (!isSoftwareHouse) {
              taxas = taxas.filter(taxa => taxa.tipoPessoa !== EnumTipoPessoas.SoftwareHouse);
            }
            taxas = taxas.filter(taxa => taxa.tipoPessoa === EnumTipoPessoas.Cliente ? taxa.tipoTaxa !== EnumTipoTaxa.VENDA : true)
            const revendaTaxaVenda = taxas.find(taxa => {
              return taxa.tipoPessoa === EnumTipoPessoas.Revendedor && taxa.tipoTaxa === EnumTipoTaxa.VENDA
            })
            if (!revendaTaxaVenda) {
              formRef.current?.fillForm(new PessoaTaxaFormModel([]));
              return
            }
            const clienteTaxa = taxas.find(taxa => taxa.pessoaId === id && taxa.tipoTaxa === EnumTipoTaxa.CUSTO);
            if (!clienteTaxa) {
              taxas = taxas.filter(taxa => taxa.id !== revendaTaxaVenda.id)
            }
            // if (!clienteTaxa || !clienteTaxa.s2PayEcId) {
            //   setShowAtivar(true);
            // }

            if (!clienteTaxa) {
              taxas.push({
                ...revendaTaxaVenda,
                tipoTaxa: EnumTipoTaxa.CUSTO,
                pessoaId: id,
                tipoPessoa: EnumTipoPessoas.Cliente,
                id: guidEmpty()
              })

              formRef.current?.fillForm(new PessoaTaxaFormModel(taxas.map(taxa => convertTaxa(taxa))))
              setShowBtn(true)
              return
            }

            if (clienteTaxa.s2PayEcId && clienteTaxa.s2PayEcDataRegistro) {
              dataRef.current = new Date(clienteTaxa.s2PayEcDataRegistro).toDateString()
            }

            formRef.current?.fillForm(new PessoaTaxaFormModel(taxas.map(taxa => convertTaxa(taxa))))
            setShowBtn(true)
            break;
          default:
            const revendaVenda = taxas.find(taxa => taxa.pessoaId === id && taxa.tipoTaxa === EnumTipoTaxa.VENDA);
            if (!revendaVenda) {
              const swTaxa = taxas.find(taxa => taxa.tipoPessoa === EnumTipoPessoas.SoftwareHouse && taxa.tipoTaxa === EnumTipoTaxa.VENDA)
              const revendaCusto = taxas.find(taxa => taxa.tipoPessoa === EnumTipoPessoas.Revendedor && taxa.tipoTaxa === EnumTipoTaxa.CUSTO)
              let taxaCusto: TaxaFormModel
              if (isSoftwareHouse) {
                if (!revendaCusto) {
                  if (!swTaxa) {
                    formRef.current?.fillForm(new PessoaTaxaFormModel([]));
                    return
                  }
                  taxaCusto = {
                    ...convertTaxa(swTaxa),
                    tipoPessoa: EnumTipoPessoas.Revendedor,
                    pessoaId: id,
                    tipoTaxa: EnumTipoTaxa.CUSTO,
                    id: guidEmpty()
                  }
                } else {
                  taxaCusto = convertTaxa(revendaCusto)
                }
              } else {
                if (!revendaCusto) {
                  formRef.current?.fillForm(new PessoaTaxaFormModel([]));
                  return
                }
                taxaCusto = convertTaxa(revendaCusto);
              }

              const taxaVenda = novoModeloTaxa(EnumTipoTaxa.VENDA, '', id, tipoPessoa);

              let arrTaxas = [taxaCusto, taxaVenda]

              if (isSoftwareHouse && swTaxa) {
                arrTaxas.unshift(convertTaxa(swTaxa));
              }

              formRef.current?.fillForm(new PessoaTaxaFormModel(arrTaxas))
              setShowBtn(true)
              return
            }

            if (!isSoftwareHouse) {
              taxas = taxas.filter((taxa) => {
                if (taxa.tipoPessoa === EnumTipoPessoas.SoftwareHouse) {
                  return false
                }
                return true
              })
            }
            if (!taxas.some(taxa => taxa.tipoTaxa === EnumTipoTaxa.CUSTO)) {
              const swTaxa = taxas.find(taxa => taxa.tipoPessoa === EnumTipoPessoas.SoftwareHouse && taxa.tipoTaxa === EnumTipoTaxa.VENDA)
              taxas.push(
                {
                  ...swTaxa!,
                  tipoTaxa: EnumTipoTaxa.CUSTO,
                  pessoaId: id,
                  tipoPessoa: EnumTipoPessoas.Revendedor,
                  id: guidEmpty()
                }
              )
              taxas = taxas.sort(sortTaxas)
            }
            formRef.current?.fillForm(new PessoaTaxaFormModel(taxas.map(taxa => convertTaxa(taxa))))
            setShowBtn(true)
            break;
        }
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getPessoaTaxa, id, isSoftwareHouse, showToast, tipoPessoa]
  );

  // const ativarCredenciamento = async () => {
  //   try {
  //     const model = new CredenciaisPostModel(id, '',
  //       new DadosAmbienteModel(EnumAmbientePagamento.Producao, EnumTipoComunicacao.PixSplit, EnumInstituicao.Safra)
  //     );
  //     const res = await postCredenciaisAtivar(model);

  //     if (res.erro) throw res.erro

  //     setShowAtivar(false);

  //     showToast('success', 'Cliente Credenciado.')

  //     search();
  //   } catch (e: any) {
  //     showToast('error', e.message)
  //   }
  // }

  const saveNovaTaxa = async (model: PessoaTaxaPostModel) => {
    const res = await postPessoaTaxa(id, model);

    if (res.erro) throw res.erro
  }

  const updateTaxa = async (model: PessoaTaxaPostModel) => {
    const res = await putPessoaTaxa(id, model);

    if (res.erro) throw res.erro
  }

  const handleSubmit = async (model: PessoaTaxaFormModel) => {
    try {
      const taxas = model.taxas
        .filter(taxa => {
          if (tipoPessoa !== EnumTipoPessoas.SoftwareHouse && taxa.tipoPessoa === EnumTipoPessoas.SoftwareHouse) {
            return false
          }
          if (tipoPessoa !== EnumTipoPessoas.Revendedor && taxa.tipoPessoa === EnumTipoPessoas.Revendedor) {
            return false
          }
          return true
        })
        .map(taxa => picker<PessoaTaxaPostModel>(taxa, new PessoaTaxaPostModel()));
      let erros: EnumTipoTaxa[] = [];
      for (let taxa of taxas) {
        try {
          if (taxa.id === guidEmpty() || taxa.id === '') {
            await saveNovaTaxa(taxa);
          } else {
            await updateTaxa(taxa)
          }
        } catch {
          erros.push(taxa.tipoTaxa)
        }
      }

      if (erros.length > 0) {
        if (erros.length > 1) {
          throw new Error(`Erro ao salvar Taxas.`);
        }
        throw new Error(`Erro ao salvar Taxas de ${TipoTaxaMock.find(x => x.Key === erros[0])?.Value.toLowerCase()}.`)
      }

      showToast('success', 'Taxas Atualizadas.')

      search();
    }
    catch (err: any) {
      showToast('error', err.message)
    }
  }


  useEffect(() => {
    if (openAccordion) {
      search()
    }
  }, [openAccordion, search])

  useEffect(() => {
    if (state && state.from.open && state.from.open === 'accordionTaxa') {
      setOpenAccordion(true)
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }, 700)
    }
  }, [state])


  return (
    <>
      <AccordionSaurus
        labelPrimary="Pix Conexão Varejo (S2Pay)"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        {carregando && <CircularLoading tipo='FULLSIZED' />}
        <div ref={scrollRef} className={classes.container}>
          <>
            <FormTaxas
              loading={carregando}
              showLoading={false}
              onSubmit={handleSubmit}
              ref={formRef}
              tipoPessoa={tipoPessoa}
              pessoaId={id}
            />
            {!isEmpty(dataRef.current) && <Box className={classes.dataCredenciamento}>
              <Typography>Data de Credenciamento: {toDateString(new Date(dataRef.current), 'DD/MM/YYYY')}</Typography>
            </Box>}
            {showBtn && <Grid container spacing={2} justifyContent='space-between' style={{ flexDirection: 'row-reverse' }}>
              <Grid item xs={12} md={3}>
                <Button fullWidth variant='contained' color='primary' onClick={() => formRef.current?.submitForm()}>
                  <SalvarIcon tipo='BUTTON_PRIMARY' />
                  Salvar
                </Button>
              </Grid>
              {/* {showAtivar && (
                <Grid item xs={12} md={3}>
                  <Button fullWidth variant='outlined' color='primary' onClick={() => ativarCredenciamento()}>
                    <OkIcon tipo='BUTTON' />
                    Ativar
                  </Button>
                </Grid>
              )} */}
            </Grid>
            }
          </>
        </div>
      </AccordionSaurus>
    </>
  );
};