import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'stretch'
        }
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    labelTipo: {
        fontSize: 20,
        color: theme.palette.grey[500],
        textAlign: 'center',
        verticalAlign: 'center',
        fontWeight: 500
    },
    labelTipoSW:{
        fontSize: '16px !important'
    },
    grow3: {
        flex: 3
    },
    grow2: {
        flex: 2
    },
    sugestaoField: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(2),
        width: '100%'
    },
    formsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    }
}));
