import { ModuloModel } from "model/api/gestao/sistemas/modulo-model";
import { EnumSituacaoSistema } from "model/enums/enum-situacao-sistema";

export class EditarSistemaFormModel {
    constructor(
        public nome: string = '',
        public descricao: string = '',
        public urlImagem: string = '',
        public situacao: EnumSituacaoSistema = EnumSituacaoSistema.Ativo,
        public modulos: ModuloModel[] = [],
        public percDescontoAntecipacao: number = 0,
        public permiteMultiContratos: boolean = false
    ) { }
}