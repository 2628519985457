
import { PessoaTaxaPostModel } from 'model/api/gestao/pessoa-taxa/pessoa-taxa-post-model';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { EnumTipoTaxa } from 'model/enums/enum-tipo-taxa';
import { useCallback, useMemo } from 'react';
import { toDecimal } from 'utils/to-decimal';
import * as Yup from 'yup';


export const useFormTaxasValidation = () => {

    //Valido se o valor de venda é maior ou igual ao valor de custo de cada campo
    const validarVendaCusto = useCallback((field: 'valorMinimo' | 'valorMaximo' | 'percentual') => {
        const campoValor = field.slice(5, field.length)
        const nomeCampo = field.includes('percentual') ? 'Taxa' : `Valor ${campoValor}`
        return {
            message: `${nomeCampo} não pode ser menor que o de custo.`,
            test: (value: string | undefined, context: any) => {

                if (context.parent.tipoPessoa === EnumTipoPessoas.SoftwareHouse) return true

                const tipo = context.parent.tipoTaxa as EnumTipoTaxa;
                if (tipo === EnumTipoTaxa.VENDA) {
                    const taxaCusto = context.from[1].value.taxas.find((taxa: PessoaTaxaPostModel) => taxa.tipoTaxa === EnumTipoTaxa.CUSTO) as PessoaTaxaPostModel;
                    if (!taxaCusto) return true
                    return toDecimal(taxaCusto[field]) <= toDecimal(value);
                }
                return true
            }
        }
    }, [])

    const FormTaxasValidationYup = useMemo(() => {
        return Yup.object().shape({
            taxas: Yup.array().of(Yup.object().shape({
                percentual: Yup.string().required('Informe a Taxa').test(validarVendaCusto('percentual')),
                valorMinimo: Yup.string().required('Informe o Valor Mínimo').test(validarVendaCusto('valorMinimo')),
                valorMaximo: Yup.string().required('Informe o Valor Máximo').test(validarVendaCusto('valorMaximo'))
            }))
        })
    }, [validarVendaCusto])

    return {
        FormTaxasValidationYup,
    }
}
