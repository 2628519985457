import { Box, Button, Card, Grid, Typography } from "@material-ui/core"
import { EnumRetornoApiBase } from "data/api/base/api-base-response"
import { useGetSistemasById, usePutAtualizarSistema } from "data/api/gestao/sistemas"
import { usePostImagemBase64 } from "data/api/imagem/post-imagem"
import { isEqual } from "lodash"
import { ModuloModel } from "model/api/gestao/sistemas/modulo-model"
import { SistemaModel } from "model/api/gestao/sistemas/sistema-model"
import { EditarSistemaFormModel } from "model/app/forms/sistemas/editar-sistema-form-model"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app"
import { imagemForUpload } from "utils/imagem-for-upload"
import { newGuid } from "utils/new-guid"
import { picker } from "utils/picker"
import { FormEditarSistema } from "views/components/form/master/sistemas/editar-sistema/form-editar-sistema"
import { DefaultFormRefs } from "views/components/form/utils"
import { OkIcon } from "views/components/icons"
import { ModulosList } from "./modulos-list/modulos-list"
import { isEmpty } from 'lodash';
import { ParametrosList } from "./parametros-list/parametros-list"
import { AmbientesList } from "./ambientes-list/ambientes-list"
import { EnumSituacaoSistema } from "model/enums/enum-situacao-sistema"
import { SituacaoSistemaMock } from "data/mocks/situacao-sistema-mock"
import { useThemeQueries } from "views/theme"
import { MenuSaurusModel } from "model/app/components/menu-saurus/menu-saurus-model"
import { useStyles } from './sistemas-by-id-content-styles'
import { MenuOptions } from "views/components/menu-options/menu-options"

type Props = {
    id: string
}

export const SistemasByIdContent = ({ id }: Props) => {

    const { getSistemasById, carregando: getSistemasByIdCarregando } = useGetSistemasById()
    const { putAtualizarSistema, carregando: putSistemaCarregando } = usePutAtualizarSistema()
    const { postImagemBase64, carregando: carregandoPost } = usePostImagemBase64()
    const classes = useStyles()
    const { theme } = useThemeQueries()
    const refSistemaModel = useRef<SistemaModel>(
        new SistemaModel(),
    );
    const { showToast } = useToastSaurus()
    const [preenchendoTela, setPreenchendoTela] = useState(true);
    const { cadastroNovoModuloState } = useCadastros()
    const { usuario } = useSessaoAtual()

    const [openModDialog, setOpenModDialog] = useState<boolean>(false)

    const carregando =
        getSistemasByIdCarregando ||
        preenchendoTela ||
        putSistemaCarregando ||
        carregandoPost

    const [modulos, setModulos] = useState<ModuloModel[] | []>([])

    const [sistemaForm, setSistemaForm] =
        useState<EditarSistemaFormModel>(new EditarSistemaFormModel());

    const refEditForm = useRef<DefaultFormRefs<EditarSistemaFormModel>>(null);

    const getSistemaByIdWrapper = useCallback(async () => {
        const res = await getSistemasById(id)

        if (res.erro) throw res.erro

        const ret = res.resultado?.data as SistemaModel

        refSistemaModel.current = ret
        const sistemaPicker = picker<EditarSistemaFormModel>(
            ret, new EditarSistemaFormModel()
        )
        sistemaPicker.modulos = [...ret.modulos]
        return sistemaPicker

    }, [getSistemasById, id])


    const preencherTela = useCallback(async () => {
        const res = await getSistemaByIdWrapper()
        res.modulos = [...res.modulos]
        setSistemaForm(res)
        setModulos(res.modulos)
    }, [getSistemaByIdWrapper])

    useEffect(() => {
        if (!openModDialog)
            (async () => {
                if (!cadastroNovoModuloState.aberto) {
                    try {
                        setPreenchendoTela(true)
                        await preencherTela()
                    }
                    catch (e: any) {
                        showToast('error', e.message)
                    }
                    finally {
                        setPreenchendoTela(false)
                    }
                }
            })()
        return () => {
            setSistemaForm(new EditarSistemaFormModel())
        }
    }, [cadastroNovoModuloState.aberto, getSistemaByIdWrapper, openModDialog, preencherTela, showToast])

    const enviarImagem = useCallback(
        async (imagemUrl: string) => {
            let imagem = '';

            const imgUpload = imagemForUpload(imagemUrl);
            if (imgUpload.length > 0) {
                const retImagem = await postImagemBase64(
                    imgUpload,
                    `licenciamento/sistemas/${usuario?.Id}/`,
                    newGuid(),
                );
                if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
                    throw new Error('Erro ao processar  a Imagem selecionada.');
                }
                if (retImagem.resultado?.data.data.status === 2) {
                    throw new Error(
                        'Erro ao processar a Imagem selecionada.Detalhe: ' +
                        retImagem.resultado?.data?.data?.retorno,
                    );
                }
                imagem =
                    retImagem.resultado?.data?.data?.url_blob +
                    '?timestamp=' +
                    new Date().getTime();
            }

            return imagem.length > 0 ? btoa(imagem) : imagem;
        },
        [postImagemBase64, usuario?.Id],
    );

    const salvarMudancasSistema = useCallback(async (sistemaModel: EditarSistemaFormModel) => {
        const sistema = picker<SistemaModel>(sistemaModel, refSistemaModel.current)

        try {
            sistema.modulos = refSistemaModel.current.modulos
            if (!isEmpty(sistema.urlImagem) && sistema.urlImagem.substring(0, 4).includes('data')) {
                sistema.urlImagem = await enviarImagem(sistema.urlImagem)
            }
            sistema.permiteMultiContratos = sistemaModel.permiteMultiContratos
            const res = await putAtualizarSistema(sistema)
            if (res.erro) throw res.erro

            showToast('success', 'Sistema Atualizado!')
            setSistemaForm(sistemaModel)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [enviarImagem, putAtualizarSistema, showToast])

    const changeSituacao = useCallback(async (situacao: EnumSituacaoSistema) => {
        try {
            const model = sistemaForm

            model.situacao = situacao

            await salvarMudancasSistema(model)

            const mensagem =
                situacao === EnumSituacaoSistema.Ativo
                    ? 'Sistema Ativado!'
                    : situacao === EnumSituacaoSistema.Bloqueado
                        ? 'Sistema Bloqueado!'
                        : 'Sistema Desativado'

            await preencherTela()
            showToast('success', mensagem)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [preencherTela, salvarMudancasSistema, showToast, sistemaForm])

    const handleSubmit = useCallback(async (model: EditarSistemaFormModel, beforeModel: EditarSistemaFormModel) => {
        const sistemaEqual = isEqual(model, beforeModel)

        if (!sistemaEqual) return await salvarMudancasSistema(model)

        showToast('error', 'Nenhuma informação foi alterada.')

    }, [salvarMudancasSistema, showToast])

    const recarregarForm = useCallback((model: EditarSistemaFormModel) => {
        refEditForm.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(sistemaForm);
    }, [recarregarForm, sistemaForm]);

    const situacaoCor = useMemo(() => {
        switch (sistemaForm.situacao) {
            case EnumSituacaoSistema.Ativo:
                return theme.palette.success.main
            case EnumSituacaoSistema.Bloqueado:
                return theme.palette.warning.main
            case EnumSituacaoSistema.Desativado:
                return theme.palette.error.main
            default:
                return theme.palette.primary.main
        }
    }, [sistemaForm.situacao, theme.palette.error.main, theme.palette.primary.main, theme.palette.success.main, theme.palette.warning.main])

    const menuOptions = useMemo(() => {
        let options: Array<MenuSaurusModel> = []

        if (sistemaForm.situacao === EnumSituacaoSistema.Ativo) {
            options.push(new MenuSaurusModel("Bloquear", <></>, () => changeSituacao(EnumSituacaoSistema.Bloqueado)))
            options.push(new MenuSaurusModel("Desativar", <></>, () => changeSituacao(EnumSituacaoSistema.Desativado)))
        }
        else if (sistemaForm.situacao === EnumSituacaoSistema.Bloqueado) {
            options.push(new MenuSaurusModel("Ativar", <></>, () => changeSituacao(EnumSituacaoSistema.Ativo)))
            options.push(new MenuSaurusModel("Desativar", <></>, () => changeSituacao(EnumSituacaoSistema.Desativado)))
        }
        else {
            options.push(new MenuSaurusModel("Ativar", <></>, () => changeSituacao(EnumSituacaoSistema.Ativo)))
        }

        return options
    }, [changeSituacao, sistemaForm.situacao])

    const createButtonRight = useMemo(() => {
        return (
            <>
                <MenuOptions
                    options={menuOptions}
                    dark
                />
            </>
        );
    }, [menuOptions]);

    return (
        <div className={classes.root}>
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <Box p={2}>
                            <div className={classes.cardBoxContent}>
                                <Typography variant="h6" >
                                    Detalhes
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <div>
                                        <div className={classes.backgroundStatus} style={{
                                            backgroundColor: situacaoCor
                                        }}>
                                            <Typography variant="body1" style={{
                                                fontWeight: 500
                                            }}>
                                                {SituacaoSistemaMock.find(item => item.Key === sistemaForm.situacao)?.Value}
                                            </Typography>
                                        </div>
                                    </div>
                                    {createButtonRight}
                                </div>
                            </div>
                            <FormEditarSistema
                                ref={refEditForm}
                                loading={carregando}
                                showLoading={true}
                                onSubmit={handleSubmit}
                            />
                            <Grid item style={{ margin: '16px 0 0' }}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12} md={3}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            onClick={() =>
                                                refEditForm.current?.submitForm()}
                                        >
                                            <OkIcon tipo="BUTTON_PRIMARY" />
                                            Salvar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <ModulosList modulos={modulos} sistemaId={id} carregando={carregando} openDialog={openModDialog}
                        setOpenDialog={setOpenModDialog} />
                </Grid>
                <Grid item xs={12}>
                    <ParametrosList sistemaId={id} />
                </Grid>
                <Grid item xs={12}>
                    <AmbientesList sistemaId={id} />
                </Grid>
            </Grid>
        </div>
    )
}