import { merge } from "lodash";
import { IVariaveisAmbiente, IAmbienteUnico } from "./types";

const AmbienteEnviado = (process.env.REACT_APP_ENV || "prodWeb") as keyof IVariaveisAmbiente;
const AmbientePadrao: keyof IVariaveisAmbiente = AmbienteEnviado;
const LabelEnviado = (process.env.REACT_APP_LABEL || "Touchone") as keyof IVariaveisAmbiente;

const apiUrl = 'https://api-licenciamento-bff-prod.touchone.com.br';
const apiImagemUrl = 'https://api-imagens.saurus.net.br';

const apiHomologacao = 'https://api-licenciamento-staging.touchone.com.br'

const apiVersao = 'v2'

const _variaveisAmbiente: IVariaveisAmbiente = {
  all: {
    version: "1.30.2",
    googleAppId: "27279191635-5kdincvmbm92lihatr0k4tm1o4kon8ue.apps.googleusercontent.com",
    saurusUtilsWebService: "https://wsmaster2.saurus.net.br/v004/serviceMaster.asmx",
    label: LabelEnviado,
    environment: AmbienteEnviado,
    isDev: (["prodWeb"]
      .find(x => x === AmbientePadrao.toString())?.length || 0) === 0,
    apiVersion: apiVersao
  },
  devWeb: {
    apiUrl: apiHomologacao,
    apiImagemUrl: apiImagemUrl,
    facebookAppId: "661218711269424",
    frontUrl: "http://localhost:3000",
    googleMapsKey: 'AIzaSyCDoIa4tkrK_R2B7br7AqiVKk7eYEBdjoo'
  },
  prodWeb: {
    apiUrl: apiUrl,
    apiImagemUrl: apiImagemUrl,
    facebookAppId: "1880946222051906",
    frontUrl: "https://licenciamento.touchone.com.br",
    googleMapsKey: 'AIzaSyCDoIa4tkrK_R2B7br7AqiVKk7eYEBdjoo'
  },
};

const _ambienteUnico =
  (_variaveisAmbiente[AmbientePadrao] as IAmbienteUnico) || _variaveisAmbiente.devWeb;
export const VariaveisAmbiente = merge(_variaveisAmbiente.all, _ambienteUnico);
