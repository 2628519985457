import { EnumInstituicao } from "model/enums/enum-instituicao";
import { EnumTipoTaxa } from "model/enums/enum-tipo-taxa";

export class PessoaTaxaPostModel {
    constructor(
      public id: string = '',
      public credencialId: string = '',
      public pessoaId: string = '',
      public tipoTransacao: number = 2,
      public instituicaoFinanceira: EnumInstituicao = EnumInstituicao.Safra,
      public tipoTaxa: EnumTipoTaxa = EnumTipoTaxa.CUSTO,
      public percentual: number = 0,
      public valorMinimo: number = 0,
      public valorMaximo: number = 0
    ) {}
  }  