import { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { makeUtilClasses } from 'views';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormTaxasValidation } from './form-taxas-validation';
import { PessoaTaxaFormModel } from 'model/app/forms/taxa/pessoa-taxa-form-model';
import { TipoTaxaMock } from 'data/mocks/tipo-taxa-mock';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { EnumTipoTaxa } from 'model/enums/enum-tipo-taxa';
import { useStyles } from './form-taxas-styles';
import { toDecimal } from 'utils/to-decimal';
import { InformacaoIcon } from 'views/components/icons';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { CredenciaisListModel } from 'model/api/gestao/credenciamento/credenciais-list-model';
import { KeyValueModel } from 'model';
import { useGetCredenciaisDisponiveis } from 'data/api/gestao/credenciamento/get-credenciais-disponiveis';
import { isEmpty } from 'lodash';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { InstituicaoPagamentoTodosMock } from 'data/mocks/instituicao-pagamento-mock';
import { guidEmpty } from 'utils/guid-empty';

interface FormTaxasProps extends DefaultFormProps<PessoaTaxaFormModel> {
    tipoPessoa: EnumTipoPessoas;
    pessoaId: string;
}

export const FormTaxas = forwardRef<
    DefaultFormRefs<PessoaTaxaFormModel>,
    FormTaxasProps
>(
    (
        {
            loading,
            onSubmit,
            model,
            tipoPessoa,
            pessoaId,
            ...props
        }: FormTaxasProps,
        ref,
    ) => {
        const utilClasses = makeUtilClasses();
        const classes = useStyles();
        const history = useHistory();
        const { showToast } = useToastSaurus();

        const { getCredenciaisDisponiveis } = useGetCredenciaisDisponiveis();

        const [credenciais, setCredenciais] = useState<CredenciaisListModel[]>([])

        const { FormTaxasValidationYup } = useFormTaxasValidation();

        const { tipoUsuario } = useSessaoAtual();

        const {
            handleSubmit,
            reset,
            getValues,
            control,
            getFieldState,
            formState: { errors },
            setValue
        } = useForm<PessoaTaxaFormModel>({
            defaultValues: new PessoaTaxaFormModel(),
            criteriaMode: 'all',
            mode: 'onSubmit',
            resolver: yupResolver(FormTaxasValidationYup)
        });

        const {
            fields,
        } = useFieldArray({
            control: control,
            name: "taxas",
        })
        const getCredenciaisWrapper = async () => {
            try {
                const res = await getCredenciaisDisponiveis(1, '&pageSize=0')

                if (res.erro) throw res.erro

                setCredenciais(res.resultado?.data.list || []);
                setValue('credencialId', res.resultado?.data.list?.[0]?.id ?? '')
            } catch (e: any) {
                showToast('error', e.message)
            }
        }

        const isFieldDisabled = (tipo: EnumTipoTaxa, tpPessoa: EnumTipoPessoas) => {
            if (tipo === EnumTipoTaxa.CUSTO) {
                switch (tipoPessoa) {
                    case EnumTipoPessoas.Revendedor:
                        return !isSoftwareHouse;
                    case EnumTipoPessoas.Cliente:
                        if (tpPessoa === EnumTipoPessoas.Cliente) {
                            return false
                        }
                        return true
                    default:
                        return false
                }
            }
            //caso seja custo da SW
            if (tipoPessoa !== EnumTipoPessoas.SoftwareHouse && tpPessoa === EnumTipoPessoas.SoftwareHouse) return true
            //case seja custo(venda) da revenda
            if (tipoPessoa !== EnumTipoPessoas.Revendedor && tpPessoa === EnumTipoPessoas.Revendedor) return true

            return false;
        }
        const retornarLabel = (tipoTaxa: EnumTipoTaxa, tpPessoa: EnumTipoPessoas, idTaxa: string) => {
            if (isSoftwareHouse && tipoPessoa !== EnumTipoPessoas.SoftwareHouse && tpPessoa === EnumTipoPessoas.SoftwareHouse) {
                return 'Custo Original'
            }
            if (tipoPessoa !== EnumTipoPessoas.Revendedor && tpPessoa === EnumTipoPessoas.Revendedor && tipoTaxa === EnumTipoTaxa.CUSTO) {
                return 'Custo'
            }
            if (
                (tpPessoa === EnumTipoPessoas.Cliente && tipoTaxa === EnumTipoTaxa.CUSTO && idTaxa === guidEmpty())
                ||
                (tpPessoa === EnumTipoPessoas.Revendedor && tipoTaxa === EnumTipoTaxa.VENDA)
            ) {
                return 'Sugerido'
            }

            if (tpPessoa === EnumTipoPessoas.Cliente && tipoTaxa === EnumTipoTaxa.CUSTO) {
                return 'Venda'
            }

            return TipoTaxaMock.find(x => tipoTaxa === x.Key)?.Value || ''
        }


        const submitEnviar = (values: PessoaTaxaFormModel) => {
            values.taxas = values.taxas
                .filter(taxa => !isFieldDisabled(taxa.tipoTaxa, taxa.tipoPessoa))
                .map(taxa => ({
                    ...taxa,
                    valorMaximo: toDecimal(taxa.valorMaximo),
                    valorMinimo: toDecimal(taxa.valorMinimo),
                    percentual: toDecimal(taxa.percentual),
                    credencialId: !isEmpty(values.credencialId) ? values.credencialId : taxa.credencialId
                }))
            onSubmit(values);
        };

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submitEnviar)();
            },
            resetForm: () => {
                reset();
            },
            fillForm: (model: PessoaTaxaFormModel) => {
                reset({ ...model })
                if (model.taxas.length > 0) {
                    if (model.taxas.some(taxa => taxa.credencialId !== guidEmpty() || !isEmpty(taxa.credencialId))) {
                        setValue('credencialId', model.taxas?.[0]?.credencialId ?? '')
                        return
                    }
                    getCredenciaisWrapper();
                }
            },
        }));

        const isSoftwareHouse = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse;

        return (
            <>
                <div>
                    <form
                        onSubmit={handleSubmit(submitEnviar)}
                        className={classNames(loading ? utilClasses.controlLoading : '', classes.formsContainer)}
                    >
                        {(fields.length > 0 && credenciais.length > 0) && <Box className={classes.container}>
                            <Controller
                                control={control}
                                name='credencialId'
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={credenciais.map(cred => new KeyValueModel(cred.id, (
                                            `${cred.instituicaoFinanceira} (${InstituicaoPagamentoTodosMock.find(x => x.Key === cred.instituicaoFinanceira)?.Value || '-'}) - CNPJ - ${formatarCPFCNPJ(cred.cpfCnpj)}`
                                        )))}
                                        readOnly={credenciais.length <= 1}
                                        label='Credenciais Disponíveis'
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.credencialId)}
                                        helperText={Boolean(errors.credencialId?.message) ?
                                            errors.credencialId?.message : undefined}
                                    />
                                )}
                            />
                        </Box>}
                        {fields.length === 0 &&
                            <Box className={classes.sugestaoField}>
                                <Box display='flex' gridGap={8}>
                                    <InformacaoIcon tipo='BUTTON' />
                                    <Typography variant='h6'>
                                        {
                                            tipoUsuario() === EnumTipoPessoas.Revendedor ?
                                                'Você não está credenciado para parametrização de taxas.' :
                                                'Revenda não está credenciada.'
                                        }
                                    </Typography>
                                </Box>
                                {(tipoPessoa === EnumTipoPessoas.Cliente && tipoUsuario() === EnumTipoPessoas.Revendedor) && <Button variant='contained' color='primary' onClick={() => {
                                    history.push({
                                        pathname: '/configuracoes',
                                        state: {
                                            from: {
                                                open: 'accordionTaxa'
                                            }
                                        }
                                    })
                                }}>
                                    <InformacaoIcon tipo='BUTTON_PRIMARY' />
                                    Ver Mais
                                </Button>}
                            </Box>
                        }
                        {fields.length > 0 && fields.map((_, i) => <Box className={classes.container}>
                            <Box className={classNames(classes.labelContainer, classes.grow2)}>
                                <Typography
                                    className={
                                        classNames(
                                            classes.labelTipo,
                                            (isSoftwareHouse && tipoPessoa !== EnumTipoPessoas.SoftwareHouse && getValues(`taxas.${i}.tipoPessoa`) === EnumTipoPessoas.SoftwareHouse)
                                                ? classes.labelTipoSW
                                                : ''
                                        )
                                    }
                                >
                                    {retornarLabel(getValues(`taxas.${i}.tipoTaxa`), getValues(`taxas.${i}.tipoPessoa`), getValues(`taxas.${i}.id`))}
                                </Typography>
                            </Box>
                            <Box className={classes.grow3}>
                                <Controller
                                    name={`taxas.${i}.valorMinimo`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            label="Valor Mínimo"
                                            tipo="DECIMAL"
                                            readOnly={isFieldDisabled(getValues(`taxas.${i}.tipoTaxa`), getValues(`taxas.${i}.tipoPessoa`))}
                                            showStartAdornment
                                            manterMascara
                                            helperText={
                                                getFieldState(`taxas.${i}.valorMinimo`).error
                                                    ? getFieldState(`taxas.${i}.valorMinimo`).error?.message
                                                    : undefined
                                            }
                                            error={Boolean(
                                                getFieldState(`taxas.${i}.valorMinimo`).error)}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className={classes.grow2}>
                                <Controller
                                    name={`taxas.${i}.percentual`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            label="Taxa (%)"
                                            readOnly={isFieldDisabled(getValues(`taxas.${i}.tipoTaxa`), getValues(`taxas.${i}.tipoPessoa`))}
                                            tipo="DECIMAL"
                                            manterMascara
                                            helperText={
                                                getFieldState(`taxas.${i}.percentual`).error
                                                    ? getFieldState(`taxas.${i}.percentual`).error?.message
                                                    : undefined
                                            }
                                            error={Boolean(
                                                getFieldState(`taxas.${i}.percentual`).error)}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className={classes.grow3}>
                                <Controller
                                    name={`taxas.${i}.valorMaximo`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            label="Valor Máximo"
                                            readOnly={isFieldDisabled(getValues(`taxas.${i}.tipoTaxa`), getValues(`taxas.${i}.tipoPessoa`))}
                                            tipo="DECIMAL"
                                            showStartAdornment
                                            manterMascara
                                            helperText={
                                                getFieldState(`taxas.${i}.valorMaximo`).error
                                                    ? getFieldState(`taxas.${i}.valorMaximo`).error?.message
                                                    : undefined
                                            }
                                            error={Boolean(
                                                getFieldState(`taxas.${i}.valorMaximo`).error)}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>)}
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </>
        );
    });