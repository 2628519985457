import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PessoaTaxaModel } from 'model/api/gestao/pessoa-taxa/pessoa-taxa-model';

export function useGetPessoaTaxa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoaTaxaModel[]>();

    const getPessoaTaxa = useCallback(
        async (pessoaId: string) => {
            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/taxas`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoaTaxa,
    };
}
